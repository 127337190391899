/* eslint-disable eqeqeq */
/*
 * @Author: lirx
 * @Date: 2022-09-10 14:02:03
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-15 10:21:19
 */
import { Dialog, Form, Input, Picker } from 'antd-mobile'
import { useTitle } from 'ahooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { editOrderState, queryAllOrderProcess } from '../../api/common'
import OrderInfo from '../component/order-info'
import storage from '../../utils/storage'

export default function () {
  const [values] = useState(() => storage.get<any>('orderInfo'))
  const userRoleId = storage.get<number>('userRoleId')
  const [orderMap, setOrderMap] = useState<any>([])
  const navigate = useNavigate()
  useEffect(() => {
    queryAllOrderProcess({
      processId: values.processId,
    }).then(res => {
      const arr = res.map((item: any) => {
        item.label = item.name
        item.value = item.id
        return item
      })
      setOrderMap(arr)
    })
  }, [])
  useTitle('订单状态更新')
  const onFinish = (pValues: any) => {
    if (!pValues.processId[0]) {
      return Dialog.alert({
        title: '温馨提示',
        content: '请选择订单状态',
      })
    }
    editOrderState({
      ...values,
      ...pValues,
      processId: pValues.processId[0],
    }).then(() => {
      Dialog.alert({
        title: '温馨提示',
        content: '订单状态更新成功',
        onConfirm() {
          navigate('/flf/orderlist')
        },
      })
    })
  }
  return (
    <OrderInfo
      disableOrderInfo
      values={values}
      btnText='修改订单状态'
      onFinish={onFinish}
    >
      <Form.Item
        trigger='onConfirm'
        name='processId'
        label='订单状态'
        onClick={(_, ref: any) => {
          ref.current.open()
        }}
        rules={[{ required: true }]}
      >
        <Picker
          cancelText='取消'
          loading={!orderMap.length}
          confirmText='确定'
          columns={[orderMap]}
        >
          {item => item?.[0]?.label || '请选择'}
        </Picker>
      </Form.Item>
      <Form.Subscribe to={['processId']}>
        {({ processId }) =>
          (userRoleId === 1
            ? processId?.[0] >= '12'
            : processId?.[0] == '12') && (
            <Form.Item
              name='logistics'
              label='物流单号'
              rules={[{ required: true }]}
            >
              <Input maxLength={50} placeholder='请输入物流单号' />
            </Form.Item>
          )
        }
      </Form.Subscribe>
    </OrderInfo>
  )
}
