/*
 * @Author: lirx
 * @Date: 2022-09-10 18:05:57
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-14 11:22:32
 */
import { useTitle } from 'ahooks'
import { Button, Dialog, Form, Input } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { queryAllOrderProcess, queryByPhone } from '../../api/common'
import storage from '../../utils/storage'
import BackGround from '../component/background'

const LoginForm = styled(Form)`
  .adm-list-body {
    background-color: transparent;
    border: none;
  }
  .adm-form-footer {
    padding: 0;
  }
  width: 100%;
  padding: 32px;
  .adm-list-item {
  }
  position: fixed;
  top: 30%;
  border: none;
  .adm-list {
    border: none;
  }
`

const FormItem = styled(Form.Item)`
  border: none;
  .adm-list-item-content {
    border: none;
  }
  border-radius: 8px;
  margin-bottom: 10px;
`
let pageNumber = 0
function Login() {
  useTitle('福临福-订单查询')
  const navigate = useNavigate()
  const onFinish = async (values: any) => {
    const append: any = await queryByPhone({
      phone: values.phone,
      'pageNumber': (pageNumber += 1),
      'pageSize': 10,
    })
    if (append.length) {
      storage.set('customerPhone', values.phone)
      queryAllOrderProcess({}).then(res => {
        const orderMap = res.map((item: any) => {
          item.label = item.name
          item.value = item.id
          return item
        })
        storage.set('orderMap', orderMap)
        navigate('/flf/orderlist')
      })
    } else {
      Dialog.alert({
        content: '暂无数据',
        title: '温馨提示',
      })
    }
  }
  return (
    <>
      <LoginForm
        layout='horizontal'
        onFinish={onFinish}
        requiredMarkStyle='none'
        footer={
          <Button block type='submit' color='success' size='large'>
            查询订单
          </Button>
        }
      >
        <FormItem
          label='手机号'
          rules={[
            {
              required: true,
              message: '请输入11位手机号码',
              pattern: /^[1]{1}[0-9]{10}$/,
            },
          ]}
          name='phone'
        >
          <Input placeholder='请输入手机号' clearable />
        </FormItem>
      </LoginForm>
      <BackGround />
    </>
  )
}
export default Login
