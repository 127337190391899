/*
 * @Author: lirx
 * @Date: 2022-09-11 16:42:31
 * @LastEditors: ztzh_lirx
 * @LastEditTime: 2022-10-09 10:08:59
 */
import styled from 'styled-components'
import logo from './img/flf-logo.png'
import Bg from './img/bg.jpg'

const Wrap = styled.div`
  background-image: url(${Bg});
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: -1;
  position: fixed;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
`

const Logo = styled.img`
  width: 200px;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
`

const Ipc = styled.a`
  position: fixed;
  text-align: center;
  width: 100%;
  bottom: 20px;
  color: #ffffff;
`

function BackGround() {
  return (
    <Wrap>
      <Logo src={logo} />
      <Ipc href='https://beian.miit.gov.cn/'>蜀ICP备2022023781号-1 </Ipc>
    </Wrap>
  )
}

export default BackGround
