/*
 * @Author: lirx
 * @Date: 2022-09-10 14:02:03
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-14 19:48:42
 */
import { Dialog } from 'antd-mobile'
import { useTitle } from 'ahooks'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { editOrderInfo } from '../../api/common'
import OrderInfo from '../component/order-info'
import storage from '../../utils/storage'

export default function () {
  const navigate = useNavigate()
  const [values] = useState<any>(() => storage.get('orderInfo'))
  useTitle('更新订单')
  const onFinish = (v: any) => {
    editOrderInfo({
      ...values,
      ...v,
    }).then(() => {
      Dialog.alert({
        title: '温馨提示',
        content: '更新订单成功',
        onConfirm() {
          navigate('/flf/orderlist')
        },
      })
    })
  }
  return (
    <OrderInfo
      values={values}
      showLogistics
      disableOrder
      onFinish={onFinish}
      btnText='更新订单'
    />
  )
}
