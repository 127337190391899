/*
 * @Author: ztzh_lirx
 * @Date: 2021-09-18 10:44:33
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-13 22:18:04
 */
import request from './request'
/**
 * 登录
 */
export const login = (param: any) =>
  request.post('user/auth/login', param, {
    showMessage: true,
    showErrorMessage: true,
  })
/**
 * 重置密码
 */
export const rePassWord = (param: any) =>
  request.get('user/auth/rePassWord', param, {
    showMessage: true,
    showErrorMessage: true,
  })
/**
 * 设置密码
 */
export const setPassWord = (param: any) =>
  request.post('user/auth/setPassWord', param, {
    showMessage: true,
    showErrorMessage: true,
  })

/**
 * 新增订单数据
 */
export const addOrder = (param: any) =>
  request.post('order/addOrder', param, {
    showMessage: true,
    showErrorMessage: true,
  })
/**
 * 更新询订单数据
 */
export const editOrderInfo = (param: any) =>
  request.post('order/editOrderInfo', param, {
    showMessage: true,
    showErrorMessage: true,
  })
/**
 * 更新询订单数据
 */
export const editOrderState = (param: any) =>
  request.post('order/editOrderState', param, {
    showMessage: true,
    showErrorMessage: true,
  })
/**
 * 查询所有的节点
 */
export const queryAllOrderProcess = (param: any) =>
  request.get('order/queryAllOrderProcess', param, {
    showMessage: true,
    showErrorMessage: true,
  })
/**
 * 根据手机号查询订单数据
 */
export const queryByPhone = (param: any) =>
  request.get('order/queryByPhone', param, {
    loadingDelay: 200,
    showMessage: true,
    showErrorMessage: true,
  })

/**
 * 查询所有的订单信息
 */
export const queryAllOrder = (param: any) =>
  request.post('order/queryAllOrder', param, {
    showMessage: true,
    showErrorMessage: true,
  })

/**
 * 拆单
 */
export const splitOrder = (param: any) =>
  request.get('order/split', param, {
    showMessage: true,
    showErrorMessage: true,
  })

/**
 * 拆单
 */
export const queryisExpire = (param: any) =>
  request.get('/order/isExpire ', param, {
    showMessage: true,
    showErrorMessage: true,
  })

/**
 * 用户确定
 */
export const userConfirm = (param: any) =>
  request.get('/order/userConfirm', param, {
    showMessage: true,
    showErrorMessage: true,
  })
