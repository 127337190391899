/*
 * @Author: lirx
 * @Date: 2022-09-10 14:34:49
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-13 21:27:41
 */
export const REQUEST_URL = '/flf/flf-service/'
export const orderMap = {
  1: '开料',
  2: '封边',
  3: '打孔',
  4: '预装',
  5: '打包',
  6: '完成',
  7: '发货',
}
