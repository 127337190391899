/*
 * @Author: ztzh_lirx
 * @Date: 2021-09-17 16:15:56
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-13 21:21:10
 */

import axios from 'axios'
import type { AxiosRequestConfig } from 'axios'
import { createHashHistory } from 'history'
import { Dialog, Toast } from 'antd-mobile'
import { REQUEST_URL } from '../constant'
import storage from '../utils/storage'
import Loading from '../pages/component/loading'

const loadingConfig = {
  target: null as any,
  count: 0,
}
let loadingDelayTimer: NodeJS.Timeout

export type CustomConfig = {
  /**
   * code不为0且不为9999时是否弹窗报错
   */
  showMessage?: boolean
  /**
   * code为9999时是否弹窗报错
   */
  showErrorMessage?: boolean
  /**
   * 是否显示loading
   */
  loading?: boolean
  /**
   * loadingDelay延时显示loading
   */
  loadingDelay?: number
}

const defaultConfig = {
  showMessage: false,
  showErrorMessage: true,
  loading: true,
  loadingDelay: 0,
}
// axios.defaults.withCredentials = true
// axios.defaults.headers.post['Content-Type'] =
//   'application/x-www-form-urlencoded'
function Axios(axiosConfig: AxiosRequestConfig, customConfig?: CustomConfig) {
  const config = {
    ...defaultConfig,
    ...customConfig,
  }
  const { showMessage, showErrorMessage, loading, loadingDelay } = config
  const instance = axios.create({
    baseURL: REQUEST_URL,
    timeout: 30000, // 请求超时
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    withCredentials: true,
  })
  instance.interceptors.request.use((requestConfig: any) => {
    if (loadingDelay) {
      if (loadingDelayTimer) clearTimeout(loadingDelayTimer)
      loadingDelayTimer = setTimeout(() => {
        openLoading()
      }, loadingDelay)
    } else if (loading) {
      openLoading()
    }
    requestConfig.headers.Authorization =
      storage.get('AToken', false) || storage.get('token', false)
    return requestConfig
  })
  /**
   * 添加响应拦截器
   */
  instance.interceptors.response.use(
    // 对响应数据做点什么
    (response: any) =>
      new Promise((resolve, reject) => {
        if (loadingDelayTimer) clearTimeout(loadingDelayTimer)
        closeLoading(config)
        if (response.data.code === '0') {
          // eslint-disable-next-line no-promise-executor-return
          return resolve(response.data.data)
        }
        if (response.data.code === '1111') {
          Toast.show('用户未登录')
          // eslint-disable-next-line no-promise-executor-return, no-restricted-globals
          location.replace(`${location.origin}/index.html#/flf/login`)
          return
        }
        if (response.data.code === '9999') {
          if (showErrorMessage) {
            Dialog.alert({
              title: '温馨提示',
              content: response.data.message,
            })
          }
          // eslint-disable-next-line no-promise-executor-return
          return reject(response.data)
        }
        if (showMessage) {
          Dialog.alert({
            title: '温馨提示',
            content: response.data.message,
          })
        }
        reject()
      }),
    error => {
      if (loadingDelayTimer) clearTimeout(loadingDelayTimer)
      closeLoading(config)
      if (showErrorMessage) {
        Dialog.alert({
          title: '温馨提示',
          content: error.message,
        })
      }
      return Promise.reject()
    }
    // 对响应错误做点什么
  )
  return instance(axiosConfig)
}
function openLoading() {
  loadingConfig.count += 1
  if (loadingConfig.count === 1) loadingConfig.target = Loading.show()
}

function closeLoading(config: CustomConfig) {
  if (config.loading && loadingConfig.count > 0) loadingConfig.count -= 1
  if (loadingConfig.count === 0) {
    loadingConfig.target?.close()
    loadingConfig.target = null
  }
}

class request {
  static async get<Data>(
    url: string,
    params: any,
    customConfig?: CustomConfig
  ): Promise<Data | any> {
    const res = await Axios(
      {
        url,
        params,
        method: 'get',
      },
      customConfig
    )
    return res
  }

  /**
   * axios post 请求封装
   * @param {String} url 接口地址
   * @param {Object} params 接口参数？可选 { type:object }
   * @param {Object} customConfig 自定义config
   * @param {Boolean} customConfig.showMessage 在code不为9999时是否弹窗报错
   * @param {Boolean} customConfig.showErrorMessage code为9999时是否弹窗报错 default true
   * @param {Boolean} customConfig.loading 是否需要loading default true
   * @param {Number} customConfig.loadingDelay loadingDealy default 0
   */
  static async post<Data>(
    url: string,
    params: any,
    customConfig?: CustomConfig
  ): Promise<Data | any> {
    const response = await Axios(
      {
        url,
        data: params,
        method: 'post',
      },
      customConfig
    )
    return response
  }
}
export default request
