/*
 * @Author: lirx
 * @Date: 2022-09-11 18:18:48
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-11 18:32:04
 */
import { Mask, SpinLoading } from 'antd-mobile'
import styled from 'styled-components'

const Wrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  text-align: center;
  color: #fff;
  transform: translate(-50%, -50%);
`

export function FlfLoading({ visible }: { visible: boolean }) {
  return (
    <Mask visible={visible} opacity='thin'>
      <Wrap>
        <SpinLoading style={{ '--size': '48px' }} />
        加载中
      </Wrap>
    </Mask>
  )
}
