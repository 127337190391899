/*
 * @Author: ztzh_lirx
 * @Date: 2021-12-15 15:41:03
 * @LastEditors: ztzh_lirx
 * @LastEditTime: 2022-09-09 14:44:43
 */
interface StorageInterface {
  // 设置localStorage
  set: (key: string, value: any) => void
  // 获取localStorage,默认会自动转json
  get: <T>(key: string, isJson?: boolean) => T
  // 是否含有key
  has: (key: string, isJson?: boolean) => boolean
  // 移除
  remove: (key: string | Array<string>) => void
}

interface SessionStorageInterface extends StorageInterface {
  // 提供localStorage操作接口
  local: StorageInterface
}

const storage: SessionStorageInterface = {} as SessionStorageInterface

// 加方法
const extend = (s: Storage): StorageInterface => ({
  set(key, value) {
    s.setItem(key, JSON.stringify(value))
  },
  get(key) {
    const item = s.getItem(key) as string
    return JSON.parse(item)
  },
  has(key) {
    return Object.keys(s).includes(key)
  },
  remove: keys => {
    if (typeof keys === 'string') {
      s.removeItem(keys)
    } else {
      keys.forEach(key => s.removeItem(key))
    }
  },
})

Object.assign(storage, extend(window.sessionStorage))
Object.assign(storage, {
  local: extend(window.sessionStorage),
})

export default storage
