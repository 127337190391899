/*
 * @Author: lirx
 * @Date: 2022-09-11 18:21:07
 * @LastEditors: lirx
 * @LastEditTime: 2022-09-11 18:27:46
 */
import { renderImperatively } from '../../../utils/render-imperatively'
import { FlfLoading } from './loading'

export const closeFnSet = new Set<() => void>()

export function show() {
  const handler: any = renderImperatively(<FlfLoading visible />)
  closeFnSet.add(handler.close)
  return handler
}
